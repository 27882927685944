
import Vue from 'vue';
import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'HowToVideo',

	components: { SectionHeading },

	props: {
		video: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			awsPath: globalAws,
			isHydrated: false,
		}
	},
	mounted() {
		this.isHydrated = true
	}
});
