
import Vue from 'vue';
import type { PropType } from 'vue';
import { Truck } from '~/types/truck';
import TruckInfoNew from '@/components/truck/TruckInfoNew.vue';

export default Vue.extend({
   name: "TruckDetails",

	components: { TruckInfoNew },

   	props: {
		dialog: {
			type: Boolean,
			default: false
		},
		truck: {
			type: Object as PropType<Truck>,
			default: null
		},
	},
});
