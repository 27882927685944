
import Vue from 'vue';

import MISC_DATA from '@/store/modules/MiscDataModule';

interface StringIndex {
	[key: string]: any;
}

const price_keys = {
	basic: 'prot1',
	standard: 'prot2',
	zero: 'prot3'
} as StringIndex;

export default Vue.extend({
	name: 'UpsellPackageCard',

	props: {
		package: {
			type: String,
			required: true
		},

		bulletPointsToShow: {
			type: Number
		},

		displayOnly: {
			type: Boolean,
			default: false
		},

		packSelected: {
			type: String,
			default: null
		},

		editingPage: {
			type: Boolean,
			default: false
		},

		packageBooked: {
			type: String
		},

		disablePack: {
			type: Boolean
		}
	},

	data() {
		return {
			expand: false,

			upsells: MISC_DATA.package_data as StringIndex
		};
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		selected(): boolean {
			return this.packSelected !== null && this.packSelected === this.package;
		},

		pack(): any {
			return this.upsells[this.package.toLowerCase()];
		},

		price(): number {
			const key = price_keys[this.package.toLowerCase()];
			return this.MISC_DATA.prices[key];
		},

		priceDifference(): number {
			if (this.packSelected !== 'NONE' && this.packageBooked && this.packageBooked !== 'none' && !this.currentlyBooked && !this.disablePack) {
				const key = price_keys[this.packageBooked.toLowerCase()];
				return this.price - this.MISC_DATA.prices[key];
			}
			return this.price;
		},

		displayPrice(): number {
			return this.editingPage ? this.priceDifference : this.price;
		},

		currentlyBooked(): boolean {
			return this.packageBooked === this.package.toLowerCase();
		},

		expandIndex(): number | null {
			if (this.bulletPointsToShow) {
				return this.bulletPointsToShow - 1;
			}
			switch (this.package) {
				case 'BASIC':
					return 0;
				case 'STANDARD':
					return 1;
				case 'ZERO':
					return 3;
			}
			return null;
		},

		packIndex(): number | null {
			switch (this.package) {
				case 'BASIC':
					return 0;
				case 'STANDARD':
					return 1;
				case 'ZERO':
					return 2;
			}
			return null;
		}
	}
});
