import { render, staticRenderFns } from "./MapTruckCardNew.vue?vue&type=template&id=2bd41ba7&scoped=true"
import script from "./MapTruckCardNew.vue?vue&type=script&lang=ts"
export * from "./MapTruckCardNew.vue?vue&type=script&lang=ts"
import style0 from "./MapTruckCardNew.vue?vue&type=style&index=0&id=2bd41ba7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd41ba7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapSelectTruckSize: require('/var/app/current/components/map/SelectTruckSize.vue').default})
