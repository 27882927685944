
import Vue from 'vue';

import MISC_DATA from '@/store/modules/MiscDataModule';
import RibbonBanner from '@/components/general/utils/RibbonBanner.vue';

import { TranslateResult } from 'vue-i18n/types';

import currentDomain from '@/mixins/currentDomain';
import globalAws from '@/mixins/globalAws';

interface StringIndex {
	[key: string]: any;
}

export default Vue.extend({
	name: 'PricingCard',

	components: { RibbonBanner },

	mixins: [currentDomain],

	props: {
		size: {
			type: String,
			default: 'L'
		}
	},

	data() {
		return {
			awsPath: globalAws,
		}
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		truckSizeInM3(): any {
			return this.MISC_DATA.data_per_bu.trucks.min_size_in_m3;
		},

		truckSizeData(): any {
			return this.MISC_DATA.basedata.size_groups;
		},

		showPromoForOneSlot(): boolean {
			if (!this.MISC_DATA.features) {
				return false;
			}
			return this.MISC_DATA.features['6_hour_blocks'] === true;
		},

		oneSlotOfftimeEnabled(): boolean {
			if (!this.MISC_DATA.features) {
				return false;
			}

			if (!this.MISC_DATA.features.offtime_slots) {
				return false;
			}

			return this.MISC_DATA.features.offtime_slots.length > 0;
		},

		oneSlotPromoPrice(): number {

			if(this.MISC_DATA.truck_min_price[this.size].price_6 !== 0){
				return this.MISC_DATA.truck_min_price[this.size].price_6;
			}

			// fallback old logic

			const prices = this.MISC_DATA.prices;
			const size = this.size.toLowerCase();
			const price_per_size = {
				l: this.oneSlotOfftimeEnabled ? prices.truck1_6off : prices.truck1_6,
				xl: this.oneSlotOfftimeEnabled ? prices.truck2_6off : prices.truck2_6,
				xxl: this.oneSlotOfftimeEnabled ? prices.truck3_6off : prices.truck3_6
			} as StringIndex;
			return price_per_size[size];
		},

		bps(): any[] {
			let bps = [] as { icon: string; text: TranslateResult }[];
			let size_text = '' as TranslateResult;

			if (this.size === 'XXL') {
				size_text = this.$t('home.price_card.size_from', [this.truckSizeData[this.size.toLowerCase()].threshold]);
			} else {
				size_text = this.$t('home.price_card.size_up_to', [this.truckSizeData[this.size.toLowerCase()].threshold]);
			}

			bps.push({ icon: '/img/pricing_cards/icons/icon_kilometer.svg', text: this.$t('home.price_card.free_km') });

			if (this.$getDomain() === 'at') {
				bps.push({ icon: '/img/pricing_cards/icons/icon_vignette.svg', text: this.$t('home.price_card.vignette') });
			}

			bps.push({ icon: '/img/pricing_cards/icons/icon_size.svg', text: size_text });

			bps.push({ icon: '/img/pricing_cards/icons/icon_support.svg', text: this.$t('home.price_card.breakdown') });

			bps.push({ icon: '/img/pricing_cards/icons/icon_license.svg', text: this.$t('home.price_card.drivers_license') });

			return bps;
		},

		image(): string {
			const images = this.MISC_DATA.data_per_bu.images.home_pricing_cards;
			switch (this.size.toUpperCase()) {
				case 'L':
					return images.l;
				case 'XL':
					return images.xl;
				case 'XXL':
					return images.xxl;
			}
			return '';
		},

		cardDisabled(): boolean {
			return this.isCzDomain && this.size === 'L';
		},

		price(): number {

			if(this.MISC_DATA.truck_min_price[this.size].price_24 !== 0){
				return this.MISC_DATA.truck_min_price[this.size].price_24;
			}

			// fallback old logic

			const keys = {
				L: 'truck1',
				XL: 'truck2',
				XXL: 'truck3'
			} as StringIndex;

			const price_key = keys[this.size];

			return MISC_DATA.prices[price_key];
		},

		ribbonText(): string {
			let ribbon_value = this.truckSizeData[this.size.toLowerCase()].banner;
			let translation_key = '';
			switch (ribbon_value) {
				case 'best_offer':
					translation_key = 'ribbon.top_offer';
					break;
				case 'coming_soon':
					translation_key = 'msg.coming_soon';
			}
			return this.$t(translation_key) as string;
		},

		isExtraSmallDevice(): boolean {
			return this.$vuetify.breakpoint.width < 400;
		},

		isSmallDevice(): boolean {
			return this.$vuetify.breakpoint.width >= 400 && this.$vuetify.breakpoint.width < 600;
		},

		isMediumDevice(): boolean {
			return this.$vuetify.breakpoint.width >= 600 && this.$vuetify.breakpoint.width < 959;
		},

		isSmallLaptop(): boolean {
			return this.$vuetify.breakpoint.mdOnly;
		},

		getCurrentDeviceSize(): any {
			if (this.isExtraSmallDevice) {
				return { width: 230, height: 110 }
			} else if (this.isSmallDevice) {
				return { width: 350, height: 170 }
			} else if (this.isMediumDevice) {
				return { width: 450, height: 240 }
			} else if (this.isSmallLaptop) {
				return { width: 168, height: 80 }
			} else {
				return { width: 235, height: 115 }
			}
		},
	},
});
