
import Vue from 'vue'

import SectionHeading from "@/components/general/utils/SectionHeading.vue"
import UpsellPackageCard from "~/components/booking/UpsellPackageCard.vue"

export default Vue.extend({

   name: "ProtectionPackages",

   components: { SectionHeading, UpsellPackageCard},

   data() {
      return {
         packages: ['basic', 'standard', 'zero']
      }
   },
   
})
