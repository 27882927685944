
import Vue from 'vue';
import type { PropType } from 'vue';

import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MISC_DATA from '@/store/modules/MiscDataModule';

import { Truck, ITruckGroupBySize } from '@/types/truck';

import MapTruckCarousel from '@/components/map/MapTruckCarousel.vue';

import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'MapChooseSize',

	components: { MapTruckCarousel },

	props: {
		trucks: {
			type: Object as PropType<ITruckGroupBySize>,
			default: null
		}
	},

	data() {
		return {
			picked_pool: [] as Truck[],
			componentUpdate: 0,
			awsPath: globalAws,
		};
	},

	computed: {
		UTILS: () => UTILS,
		TRUCKS: () => TRUCKS,
		MISC_DATA: () => MISC_DATA,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		dialogTruckCarousel(): boolean {
			return this.$route.query.dialog === 'dialog-tr-carousel';
		},

		sizeCards(): any {
			const size_cards = [
				{
					label: 'L',
					color: 'cyan123',
					truck_amount: this.trucks.l ? this.trucks.l.length : 0,
					truck_price: this.MISC_DATA.truck_min_price.L.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price.L.price_6
				},
				{
					label: 'XL',
					color: 'orange123',
					truck_amount: this.trucks.xl ? this.trucks.xl.length : 0,
					truck_price: this.MISC_DATA.truck_min_price.XL.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price.XL.price_6,
				},
				{
					label: 'XXL',
					color: 'pink123',
					truck_amount: this.trucks.xxl ? this.trucks.xxl.length : 0,
					truck_price: this.MISC_DATA.truck_min_price.XXL.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price.XXL.price_6
				}
			];
			return size_cards.filter((c: any) => c.truck_amount > 0);
		},

		showPromoForOneSlot(): boolean {
			if (!this.MISC_DATA.features) {
				return false;
			}
			return this.MISC_DATA.features['6_hour_blocks'] === true;
		}
	},

	methods: {
		pickedSize(size: string): void {
			this.componentUpdate += 1;
			this.picked_pool = this.trucks[size];
			this.UTILS.openDialog('dialog-tr-carousel');
		}
	}
});
