import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=4313dc5a&scoped=true"
import script from "./Home.vue?vue&type=script&lang=ts"
export * from "./Home.vue?vue&type=script&lang=ts"
import style0 from "./Home.vue?vue&type=style&index=0&id=4313dc5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4313dc5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapBanner: require('/var/app/current/components/map/MapBanner.vue').default,MapWrapper: require('/var/app/current/components/map/MapWrapper.vue').default,HomeOfficialPartnersText: require('/var/app/current/components/home/OfficialPartnersText.vue').default,HomeOfficialPartners: require('/var/app/current/components/home/OfficialPartners.vue').default,HomeAdvantages: require('/var/app/current/components/home/Advantages.vue').default,HomeSummary123: require('/var/app/current/components/home/Summary123.vue').default,HomeHowToVideo: require('/var/app/current/components/home/HowToVideo.vue').default,HomePricing: require('/var/app/current/components/home/Pricing.vue').default,HomeCustomerReviews: require('/var/app/current/components/home/CustomerReviews.vue').default,HomeProtectionPackages: require('/var/app/current/components/home/ProtectionPackages.vue').default,HomePartners: require('/var/app/current/components/home/Partners.vue').default,HomeKnownFrom: require('/var/app/current/components/home/KnownFrom.vue').default,GeneralUtilsInvoiceBanner: require('/var/app/current/components/general/utils/InvoiceBanner.vue').default})
