
import Vue from 'vue';
import type { PropType } from 'vue';

import dayjs from 'dayjs';

import { gmapApi } from 'vue2-google-maps';
import { groupBy } from '@/_helpers/misc_helper';
import { VGmap } from '@/types/index';
import { routesNames } from '@/router';

import Booking from '@/types/booking/booking';
import { Truck, ITruckFilterSettings, ITruckGroupBySize, Site } from '@/types/truck';
import { IPartner, IPartnerLocation } from '@/types/misc_data';
import { ErrorResponse } from '~/types/api_helper';

import MapHeader from '@/components/map/MapHeader.vue';
import MapTruckCarousel from '@/components/map/MapTruckCarousel.vue';
import MapTruckCarouselNew from '@/components/map/MapTruckCarouselNew.vue';
import MapTruckCard from '@/components/map/MapTruckCard.vue';
import MapTruckCardNew from '@/components/map/MapTruckCardNew.vue';

import MISC_DATA from '@/store/modules/MiscDataModule';
import CART from '@/store/modules/CartModule';
import UTILS from '@/store/modules/UtilityModule';
import MAP from '@/store/modules/MapModule';
import BOOKING from '@/store/modules/BookingModule';
import TRUCKS from '@/store/modules/TruckModule';
import currentDomain from '@/mixins/currentDomain';
import cookieConsent from '@/mixins/cookieConsent';

interface StringIndex {
	[key: string]: any;
}

export default Vue.extend({
	name: 'MapWrapper',

	components: { MapHeader, MapTruckCarousel, MapTruckCarouselNew, MapTruckCard, MapTruckCardNew },

	mixins: [currentDomain, cookieConsent],

	props: {
		currentPartner: {
			type: Object as PropType<IPartner>,
			default: null
		}
	},

	data() {
		return {
			dayjs: dayjs,
			map: {
				bounds: {
					min_lat: 45,
					max_lat: 56,
					min_lng: 5,
					max_lng: 20
				},
				ready: false,
				show_content: false,
				center: { lat: 0, lng: 0 },
				zoom: 8,
				explain_box: false,
				filter: {
					available: true,
					unavailable: false,
					hook: false,
					no_stickers: false,
					length: 200,
					volume: 3,
					seats_3: false
				}
			},
			current_zoom: 8,
			map_fullscreen: false,
			map_class: 'standard-map',
			offset_top: null as number | null,
			offset_map: null as number | null,
			fetch_truck_buffer: 0,
			truck_loading: false,
			truck_query: false,
			truck_location: '',
			truck_groups_in_view: [] as ITruckGroupBySize[],
			current_size_group: {} as ITruckGroupBySize,
			size_group_clicked: [] as Truck[],
			cluster_was_clicked: false,
			truck_clicked: '',
			truck_clicked_availability: false,
			map_offset_top: '62px',
			viewport_height: '100vh',
			default_center: { lat: 0, lng: 0 },
			default_zoom: 8,
			search_input: '',
			searched_place: '',
			componentUpdate: 0,
			selected_location: null as IPartnerLocation | null,
			isNewDesign: false,
		};
	},

	computed: {
		google: gmapApi,

		MAP: () => MAP,
		CART: () => CART,
		BOOKING: () => BOOKING,
		TRUCKS: () => TRUCKS,
		UTILS: () => UTILS,
		MISC_DATA: () => MISC_DATA,

		mapRef(): VGmap | undefined {
			return this.$refs.mapRef as VGmap | undefined;
		},

		clusterRef(): any {
			return this.$refs.clusterRef as any;
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		showMapSearch(): boolean {
			return this.isMobile && this.map.ready && !this.currentPartner;
		},

		showMapAutocompleteSearch(): boolean {
			return !this.isMobile && this.map.ready && !this.currentPartner;
		},

		dialogSizeGroupCarousel(): boolean {
			return this.$route.query.dialog === 'dialog-size-gr-carousel';
		},

		showSizeGroupMarkers(): boolean {
			return this.current_zoom >= 20;
		},

		icons(): any {
			const google = this.google as any;

			return {
				l: {
					available: {
						orange: {
							url: '/icons/gmap/van-l.svg',
							anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2), // !!! otherwise marker gets positioned in the wrong place
							scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
						},
						pink: {
							url: '/icons/gmap/van-l-pink.svg',
							anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2), // !!! otherwise marker gets positioned in the wrong place
							scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
						}
					},
					unavailable: {
						url: '/icons/gmap/van-l-unavailable.svg',
						anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
						scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
					}
				},
				xl: {
					available: {
						orange: {
							url: '/icons/gmap/van-xl.svg',
							anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
							scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
						},
						pink: {
							url: '/icons/gmap/van-xl-pink.svg',
							anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
							scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
						}
					},
					unavailable: {
						url: '/icons/gmap/van-xl-unavailable.svg',
						anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
						scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
					}
				},
				xxl: {
					available: {
						orange: {
							url: '/icons/gmap/van-xxl.svg',
							anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
							scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
						},
						pink: {
							url: '/icons/gmap/van-xxl-pink.svg',
							anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
							scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
						}
					},
					unavailable: {
						url: '/icons/gmap/van-xxl-unavailable.svg',
						anchor: new google.maps.Point(52 * 1.6 / 2, 30 * 1.6 / 2),
						scaledSize: new google.maps.Size(52 * 1.6, 30 * 1.6)
					}
				}
			};
		},

		lpMapData(): typeof MISC_DATA.LP_map_data {
			return this.MISC_DATA.LP_map_data;
		},

		lpMapZoom(): number | null {
			if (!this.lpMapData) {
				return null;
			}

			if (this.isMobile && this.lpMapData.zoom_mobile) {
				return this.lpMapData.zoom_mobile;
			}

			return this.lpMapData.zoom;
		},

		lpCoordinates(): { lat: number; lng: number } {
			return {
				lat: this.lpMapData.lat,
				lng: this.lpMapData.lng
			};
		},

		dialogTruckMap(): boolean {
			if (this.$route.query.dialog) {
				return this.$route.query?.dialog.includes('truck'); // So it does not close when opening futher dialog truck info
			}
			return false;
		},

		dialogChooseSize(): boolean {
			return this.$route.query.dialog === 'dialog-choose-size';
		},

		dialogNotAvailableMessage(): boolean {
			return this.$route.query.dialog === 'show_not_available_message';
		},

		partners(): IPartner[] {
			// Used by the map for location markers
			return this.MISC_DATA.partners;
		},

		clusterBySize(): StringIndex {
			const truck_list = [] as any[];
			for (const bu in this.MAP.all_trucks) {
				const country_trucks = this.MAP.all_trucks[bu];
				for (const tid in country_trucks) {
					truck_list.push(JSON.parse(JSON.stringify(country_trucks[tid])));
				}
			}

			const result = {} as StringIndex;
			// Return a list of objects using site location as index and containing their respective site trucks grouped by size.
			// Check groupBySize for handling of marker position for each size.
			Array.from(groupBy(truck_list, (truck: any) => truck.position.site_id)).map((el: [string, any[]]) => {
				return (result[el[0]] = this.groupBySize(el[1]));
			});
			return result;
		},

		showPartnersLocations(): boolean {
			return this.partners && this.current_zoom >= 13 && !this.currentPartner;
		},

		showDateTimePickerSection(): boolean {
			// return !this.isCzDomain && !this.isDeDomain;
			return this.currentPartner !== null;
		},

		promoBannerTranslationKey(): string | null {
			return this.MISC_DATA.basedata.promo_text;
		},

		mapIsInSearchMode(): boolean {
			return !this.searched_place || this.searched_place !== this.search_input;
		},
		partnerLocations(): IPartnerLocation[] | null {
			if (this.currentPartner) {
				return this.currentPartner.locations.slice().sort((a, b) => a.name.localeCompare(b.name));
			}
			return null
		},
		currentMapSettings(): { lat: number; lng: number; zoom: number } | null {
			if (this.selected_location) {
				return {
					lat: this.selected_location.map.lat,
					lng: this.selected_location.map.lng,
					zoom: 15
				};
			}
			return null;
		},
	},

	watch: {
		offset_top() {
			if (this.offset_map && this.offset_top && !this.isMobile) {
				if (this.offset_top - this.offset_map > 150 || this.offset_top < 50) {
					this.resizeMap('standard-map');
					this.MAP.expandFilterState(false);
				}
			}
		},

		lpCoordinates() {
			if (this.lpCoordinates.lat) {
				this.map.center = this.lpCoordinates;
				this.map.zoom = this.lpMapZoom || 14;
			}
		},

		lpMapZoom() {
			this.map.zoom = this.lpMapZoom ? this.lpMapZoom : 14;
		},

		clusterBySize() {
			// This is used to update the size group markers when modifying the filter.
			if (this.CART.date_time_picked && this.showSizeGroupMarkers) {
				this.mapIdle();
			}
		},

		async currentPartner() {
			MAP.updateTrucksList([]);
			this.map.zoom = 6;
			this.truck_loading = true;
			this.map.show_content = false;
			await this.fetchTruckList();
		},

		// after load of map, get the top offset of the map, so we can later use it to scale the map
		// the map then is fixed to the size of the sceen - the offset
		// just on mobile
		'map.ready'() {
			this.$nextTick(() => {
				this.setMapHeight();
			});
		},
		'$vuetify.breakpoint.height'() {
			this.setMapHeight();
		},
	},

	async created() {
		this.map.ready = false;
		this.map.show_content = false;
		this.setMapDefaults();

		await (this as any).$gmapApiPromiseLazy().then(async () => {
			this.map.ready = true;

			await this.fetchTruckList();

			this.UTILS.setPageLoader(false);

			//	Check for truck query - QR code on truck -> Open truck dialog with calendar preselected
			if (this.$route.query.truck) {
				this.truck_query = typeof this.$route.query.truck === 'string';
				await this.truckClicked(this.$route.query.truck as string);
			}

			// Check queries (Truck QR code / Coordinates / Zoom)

			// Get partners location - We will display their icons on the map at a certain zoom level
			// console.log('MAP partners', this.MAP.partners);
			if (this.MISC_DATA.partners && this.MISC_DATA.partners.length > 0) {
				this.MISC_DATA.partners.forEach(async (partner: IPartner) => {
					await this.MISC_DATA.GET_PARTNER_LOCATIONS({ partner_id: partner.id, bu: this.$getDomain() });
				});
			}

			// If on Landing pages and mobile FocusOnMap
			// if (this.$route.name === routesNames.landing_page && this.isMobile) {
			// 	await this.focusOnMap();
			// }
		});
		this.map.show_content = true;

		// Check if searched time are currently in localStorage and store them
		if (!this.CART.booking.id && localStorage && localStorage.getItem('date-time-searched')) {
			CART.STORE_DATE_TIME_PICKED(JSON.parse(localStorage.getItem('date-time-searched') as string));
		}
	},

	mounted() {
		if (this.$route.query.failed) {
			// If booking create/update request fails
			// this.$store.dispatch('storeBookingData', {time_start: null, time_end: null}) Eventually clear dates if failed redirect creates issues again
			this.UTILS.openDialog('show_not_available_message');
		}
		this.setMapHeight();
	},

	methods: {
		setMapDefaults() {
			// !! do the route check, because store might be populated from shared server vuex instance
			if (this.$route.name === routesNames.landing_page) {
				this.$logger.console({ message: 'MapWrapper using LP location' });
				this.map.center = this.lpCoordinates;
				this.map.zoom = this.lpMapZoom || 14;
				return;
			}
			// On Home only check if a map position was saved (current position upon going to upsells - removed upon arriving to checkout)
			if (this.$route.name === routesNames.home) {
				const saved_position = this.MAP.saved_map_position;
				if (saved_position && saved_position.center && saved_position.center.lat) {
					this.map.center = saved_position.center;
					this.map.zoom = saved_position.zoom;
					return;
				}
			}

			if (process.client) {
				const stored_filter = sessionStorage.getItem('map-filter');
				// We changed the structure of the filter and since we store it in session it's better to handle this for now
				if (stored_filter && !Object.keys(JSON.parse(stored_filter)).includes('length_range')) {
					sessionStorage.setItem('map-filter', JSON.stringify(new ITruckFilterSettings()));
				}
			}

			// set center for default or partner-lp
			const lat = this.$route.query.m_lat ? this.$route.query.m_lat : this.MISC_DATA.data_per_bu.map_center.lat;
			const lng = this.$route.query.m_lng ? this.$route.query.m_lng : this.MISC_DATA.data_per_bu.map_center.lng;
			const zoom = this.$route.query.m_zoom ? this.$route.query.m_zoom : this.MISC_DATA.data_per_bu.map_center.zoom;

			this.default_center = { lat: +lat, lng: +lng };
			this.default_zoom = +zoom;

			this.map.center = this.default_center;
			this.map.zoom = this.default_zoom;
		},

		setMapHeight() {
			this.viewport_height = this.$vuetify.breakpoint.height + 'px';
			const element = document.getElementById('map-view');
			if (element) {
				function getParentOffset(el: HTMLElement): number {
					if (el.tagName === 'BODY' || el.offsetParent == null || !(el.offsetParent instanceof HTMLElement)) {
						return 0;
					}
					return el.offsetTop + getParentOffset(el.offsetParent);
				}

				this.map_offset_top = getParentOffset(element) + 'px';
			}
		},

		getMapOptions() {
			let additionalDomain = '';

			const domainCountryCode = this.$getDomain().toUpperCase();

			if (domainCountryCode === 'AT') {
				additionalDomain = 'DE';
			} else if (domainCountryCode === 'DE') {
				additionalDomain = 'AT';
			}

			if (additionalDomain) {
				return {
					componentRestrictions: {
						country: [domainCountryCode, additionalDomain]
					}
				};
			}

			return {
				componentRestrictions: {
					country: [domainCountryCode]
				}
			};
		},

		handleSearch(): void {
			if (this.search_input.trim() === '') {
				return;
			}

			const google = this.google as any;
			const geocoder = new google.maps.Geocoder();
			const allowedCountries = [this.$getDomain().toUpperCase()];

			geocoder.geocode({ address: this.search_input }, (results: any, status: any) => {
				if (status === 'OK' && results.length > 0) {
					// Filter results to find the first match in the allowed countries
					const validResult = results.find((result: { address_components: any[] }) =>
						result.address_components.some(
							(ac: { types: string | string[]; short_name: string }) => ac.types.includes('country') && allowedCountries.includes(ac.short_name)
						)
					);

					if (validResult) {
						const newCenter = {
							lat: validResult.geometry.location.lat(),
							lng: validResult.geometry.location.lng()
						};
						const default_zoom = this.getZoomBasedOnPlaceType(validResult); // Sets initial zoom based on place type
						const optimalZoom = this.findMaxZoomThatIncludesTrucks(newCenter, default_zoom);

						// Pan to the new location with the calculated optimal zoom
						this.panToLocation({ lat: newCenter.lat, lng: newCenter.lng, zoom: optimalZoom });
						this.searched_place = this.search_input;
					}
				} else {
					console.error('Geocode was not successful for the following reason: ' + status);
				}
			});
		},

		clearSearch(): void {
			this.search_input = '';
			this.searched_place = '';
			this.resetMapToDefault();
		},

		searchFieldIconClicked(): void {
			if (this.mapIsInSearchMode) {
				this.handleSearch();
			} else {
				this.clearSearch();
			}
		},

		resetMapToDefault(): void {
			// Reset the map to a default location
			this.panToLocation({ lat: this.default_center.lat, lng: this.default_center.lng, zoom: this.default_zoom });
		},

		updateSearchInput(event: any): void {
			// Check if the event is an InputEvent and has a target that is an input element
			if (event && event.target) {
				this.search_input = event.target.value;
			}
		},

		handlePlaceSelect(place: any): void {
			if (!place.geometry) {
				return
			}
			const newCenter = {
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng()
			};
			const default_zoom = this.getZoomBasedOnPlaceType(place); // Sets initial zoom based on place type

			// Use findMaxZoomThatIncludesTrucks to find the optimal zoom that includes at least one truck
			const optimalZoom = this.findMaxZoomThatIncludesTrucks(newCenter, default_zoom);

			// Pan to the new location with the calculated optimal zoom
			this.panToLocation({ lat: newCenter.lat, lng: newCenter.lng, zoom: optimalZoom });
			this.search_input = place.name;
		},

		findMaxZoomThatIncludesTrucks(newCenter: any, startZoom: any) {
			const minZoom = this.isMobile ? 6 : 7;
			if (startZoom < minZoom) {
				return startZoom;
			}
			for (let checkedZoom = startZoom; checkedZoom >= minZoom; checkedZoom--) {
				const bounds = this.calculateNewBounds(newCenter, checkedZoom);
				if (this.checkIfAnyTruckIsWithinBounds(bounds.newNe, bounds.newSw)) {
					return checkedZoom;
				}
			}
			return minZoom;
		},

		checkIfAnyTruckIsWithinBounds(newNe: any, newSw: any) {
			const filteredTrucksValues = Object.values(this.MAP.all_trucks as { [key: string]: { [key: string]: any[] } });
			const mappedFilteredTrucksValues: any[][] = filteredTrucksValues.map((countryTrucks) => Object.values(countryTrucks as { [key: string]: any[] }).flat());
			const trucks = ([] as any[]).concat(...mappedFilteredTrucksValues);

			return trucks.some((truck) => {
				return truck.position.lat <= newNe.lat && truck.position.lat >= newSw.lat && truck.position.lng <= newNe.lng && truck.position.lng >= newSw.lng;
			});
		},

		getZoomBasedOnPlaceType(place: any): number {
			const types = place.types; // Array of types associated with the place
			const ZOOMS = {
				mobile: {
					smallArea: 14,
					city: 10,
					country: 6,
					special: 11
				},
				standard: {
					smallArea: 15,
					city: 12,
					country: 7,
					special: 12
				}
			};
			const zooms = this.isMobile ? ZOOMS.mobile : ZOOMS.standard;

			if (types.includes('street_address') || types.includes('premise') || types.includes('route') || types.includes('neighborhood') || types.includes('point_of_interest') || types.includes('establishment')) {
				return zooms.smallArea;
			} else if (types.includes('locality')) {
				// City, ex. Prague
				return zooms.city;
			} else if (types.includes('country')) {
				return zooms.country;
			}
			return zooms.special;
		},

		calculateNewBounds(newCenter: any, newZoom: any) {
			// Get the current state of the map
			const mapObject = this.mapRef?.$mapObject;
			const currentZoom = mapObject?.getZoom();
			const currentBounds = mapObject?.getBounds();
			const currentCenter = currentBounds.getCenter();

			// Calculate the current differences from center to edges
			const currentNe = currentBounds.getNorthEast();
			const latHeight = Math.abs(currentNe.lat() - currentCenter.lat());
			const lngWidth = Math.abs(currentNe.lng() - currentCenter.lng());

			// Determine the scale factor based on zoom difference
			const scale = Math.pow(2, currentZoom - newZoom);

			// Calculate the new boundaries using the scale factor
			const newNeLat = newCenter.lat + latHeight * scale;
			const newNeLng = newCenter.lng + lngWidth * scale;
			const newSwLat = newCenter.lat - latHeight * scale;
			const newSwLng = newCenter.lng - lngWidth * scale;

			return {
				newNe: { lat: newNeLat, lng: newNeLng },
				newSw: { lat: newSwLat, lng: newSwLng }
			};
		},

		getClusterStyles(bu: string): any[] {
			const clusters_styles = JSON.parse(JSON.stringify(this.MAP.cluster_styles));

			if (this.currentPartner) {
				clusters_styles.main.forEach((icon: any) => {
					icon.url = this.currentPartner.map_icon;
					icon.textColor = 'transparent';
					icon.width = 64;
					icon.height = 64;
				});
				return clusters_styles.main;
			}
			return bu === this.$getDomain() ? clusters_styles.main : clusters_styles.foreign;
		},

		mapIdle() {
			// Called on map area change
			this.truck_groups_in_view = [];
			this.current_zoom = this.mapRef?.$mapObject.getZoom(); // Keep track of zoom level for Partner markers.

			// To get current lat lng (When changes need to be made for default map center that's very handy)
			// console.log((this.mapRef.$mapObject as any).data.map.center.lat())
			// console.log((this.mapRef.$mapObject as any).data.map.center.lng())

			if (this.current_zoom < 16) {
				return;
			}

			// if current zoom if >= 16 get current trucks in bounds ->
			// Get trucks from all countries (They are now separated in their own bu key)
			const truck_list = [] as any;
			for (const bu in this.MAP.all_trucks) {
				const country_trucks = this.MAP.all_trucks[bu];
				for (const tid in country_trucks) {
					truck_list.push(JSON.parse(JSON.stringify(country_trucks[tid])));
				}
			}

			const sites_in_view = [] as string[];

			truck_list.map((truck: any) => {
				if (!truck.position || !this.mapRef?.$mapObject.getBounds().contains(truck.position)) {
					return null;
				}
				if (!truck.position && !truck.position.site_id) {
					this.$logger.console({ message: 'Truck has no site_id', data: truck.id, sentry_log: true });
					return null;
				}
				const site_id = truck.position.site_id;

				if (!site_id) {
					return null;
				}
				return sites_in_view.includes(site_id) ? null : sites_in_view.push(site_id);
			});

			sites_in_view.forEach((site: string) => {
				this.truck_groups_in_view.push(this.clusterBySize[site]);
			});

			// If a cluster was clicked, handles if we should open the ChooseSize / TruckCarousel or do nothing
			this.handleClusterClicked();
		},

		clusterClicked(cluster: any): void {
			// It saddens me but it kinda has to be handled that way.
			// Reason is that the cluster click event happens before idle() is done therefore we miss all the data.
			// All of it was initially done here (passing event and getting markers) but then it isn't run if the user zooms in instead on cluster clicking.
			// So this is basically a switch that will trigger opening ChooseSize or TruckCarousel, handled by handleClusterClicked().

			// Special handling for just one site in cluster -< Prevents zooming in when only one site inn the cluster just clicked.
			const site_id = cluster.markers_[0].site;
			let only_one = true;

			for (const i in cluster.markers_) {
				if (site_id !== cluster.markers_[i].site) {
					only_one = false;
					break;
				}
			}

			if (only_one) {
				const truck_group = this.clusterBySize[site_id] as { [index: string]: Truck[] };
				this.handleTruckGroupOpening(truck_group);
				return;
			}

			// if more sites are hidden in the currently clicked on cluster, zoom in ->
			this.cluster_was_clicked = true;
			cluster.map_.fitBounds(cluster.bounds_);
		},

		sizeGroupClicked(group: Truck[]) {
			// Passed to the TruckCarousel
			this.size_group_clicked = group;
			this.UTILS.openDialog('dialog-size-gr-carousel');
		},

		handleClusterClicked(): void {
			if (!this.cluster_was_clicked) {
				return;
			}

			const groups_in_view = this.truck_groups_in_view;

			let truck_group;
			if (groups_in_view.length > 1) {
				// Merge all groups in view (can't know which one was clicked and they are veryy close to each other). (Edge case eg. Graz - Sankt-Peter Gurtel)
				const result = groups_in_view[0];
				groups_in_view.shift();
				groups_in_view.forEach((group: ITruckGroupBySize) => {
					for (const size in group) {
						result[size] = result[size] ? result[size].concat(group[size]) : group[size];
					}
				});
				truck_group = result;
			} else {
				truck_group = groups_in_view[0];
			}

			if (!truck_group) {
				return;
			}

			this.handleTruckGroupOpening(truck_group);

			this.cluster_was_clicked = false;
		},

		handleTruckGroupOpening(truck_group: { [index: string]: Truck[] }): void {
			const t = Object.values(truck_group)[0][0];

			// Take first truck and check BU in case redirect is needed
			const truck_bu = t.bu.toLowerCase();
			if (truck_bu && truck_bu !== this.$getDomain()) {
				this.$logger.console({ message: 'Truck belongs to another BU - redirecting now' });
				this.domainRedirect(truck_bu, t.id);
				return;
			}

			// Open choose size dialog if more than 1 size
			if (Object.keys(truck_group).length > 1) {
				// More than 1 size -> open choose size dialog
				this.current_size_group = truck_group;
				if (!this.dialogChooseSize) {
					this.componentUpdate += 1;
					this.UTILS.openDialog('dialog-choose-size');
				}
			} else {
				// Only 1 size -> open truck carousel
				this.sizeGroupClicked(Object.values(truck_group)[0]);
			}
		},

		async truckClicked(id: string, availability?: boolean): Promise<void> {
			const params = {
				truck_id: id
			};

			const truck = await this.TRUCKS.GET_TRUCK_DATA(params);
			if (truck instanceof ErrorResponse) {
				this.$logger.console({ message: 'GET_TRUCK_DATA in MapWrapper error' });
				return;
			}

			const truck_bu = truck.bu.toLowerCase();
			if (truck_bu && truck_bu !== this.$getDomain()) {
				this.$logger.console({ message: 'Truck belongs to another BU - redirecting now' });
				this.domainRedirect(truck_bu, truck.id);
				return;
			}
			// Check once more in case truck has changed but query is somehow still present // edge cases
			this.truck_query = truck.id === this.$route.query.truck;

			if (truck.location && truck.location instanceof Site) {
				this.truck_location = truck.location.id;
			}

			if (availability !== undefined) {
				this.truck_clicked_availability = availability;
			}

			this.openTruckDetails(id);
		},

		groupBySize(cluster: any[]): any {
			if (cluster.length < 1) {
				return;
			}
			// Create an object of keys l, xl, xxl (if at least 1) containing their respective trucks.
			const size_groups = {} as StringIndex;
			Array.from(groupBy(cluster, (truck: any) => truck.size_group)).map((el: [string, any[]]) => {
				return (size_groups[el[0].toLowerCase()] = el[1]);
			});

			return size_groups;
		},

		async fetchTruckList(): Promise<void> {
			this.truck_loading = true;
			this.map.show_content = false;
			const { min_lat, max_lat, min_lng, max_lng }: any = this.map.bounds;

			let start = null;
			let end = null;
			let partner_id = null;

			if (this.currentPartner) {
				this.CART.resetDateTimePicked();
				partner_id = this.currentPartner.id;

				// In case partner location truck list fails a few times -> Redirect home cause without trucks the locations make no sense
				if (this.fetch_truck_buffer >= 3) {
					// reset map bounds
					this.setMapDefaults();

					this.$router.replace({ name: routesNames.home });
				}
			}

			if (this.CART.date_time_picked && !this.currentPartner) {
				const date_times = JSON.parse(JSON.stringify(this.CART.last_time_search));
				start = date_times.start;
				end = date_times.end;
			}

			const params = {
				min_lat: min_lat,
				max_lat: max_lat,
				min_lng: min_lng,
				max_lng: max_lng,
				start: start,
				end: end,
				partner_id: partner_id as string
			};

			const res = await this.TRUCKS.FETCH_TRUCK_LIST(params);

			if (res instanceof ErrorResponse && this.fetch_truck_buffer <= 5) {
				await this.panToLocation(this.map.center);

				this.fetch_truck_buffer++;
				await this.fetchTruckList();
				return;
			}

			this.fetch_truck_buffer = 0;
			this.truck_loading = false;
			this.map.show_content = true;
		},

		truckMarker(truck: any): any {
			const size = truck.size_group.toLowerCase();
			// set truck icons to be orange in current domain country or pink in other countries
			const available_icon = truck.bu.toLowerCase() === this.$getDomain() ? this.icons[size].available.orange : this.icons[size].available.pink;
			return truck.available ? available_icon : this.icons[size].unavailable;
		},

		async panToLocation(loc: { lat: number; lng: number; zoom?: number }): Promise<void> {
			this.$logger.console({ message: `panTolocation - mapSettings: ${loc}` });
			if (typeof loc.zoom === 'number') {
				this.map.zoom = loc.zoom;
			}
			await this.mapRef?.$mapPromise.then((map: any) => {
				map.panTo({ lat: loc.lat, lng: loc.lng });
			});
		},

		onScroll(e: Event | any): void {
			this.offset_top = e.target.scrollingElement.scrollTop;
		},

		async focusOnMap() {
			this.offset_map = null;

			if (!this.isMobile) {
				this.resizeMap('fullscreen-map');
			}
			await this.$vuetify.goTo('#map-view', { duration: 320, offset: this.isMobile ? 160 : 0, easing: 'easeInOutCubic' });
			this.offset_map = this.offset_top;

			if (!this.isMobile && this.CART.date_time_picked) {
				this.MAP.expandFilterState(true);
			}
		},

		resizeMap(name: string): void {
			this.map_class = name;
		},

		openTruckDetails(id: string, new_loc?: string): void {
			if (new_loc) {
				this.truck_location = new_loc; // used by the MapChooseSize emit cause truck_location might be different than the originally clicked truck.
			}
			this.truck_clicked = id;
			if (!this.dialogTruckMap) {
				UTILS.openDialog('dialog-map-truck');
			}
		},

		domainRedirect(bu: string, truck_id: string) {
			this.UTILS.closeDialog('dialog-map-truck');
			this.UTILS.setRedirectLoader(true);
			const base_url = this.$config[`url_${bu}`] as string;
			setInterval(() => {
				this.$logger.console({ message: 'redirectDomain', data: bu });
			}, 1500);
			window.location.href = `${base_url}?truck=${truck_id}`;
		},

		async toUpsells(truck: string) {
			(this as any).$tracking.click('Zeiten mit Vorauswahl');
			const booking = new Booking(this.CART.booking);
			booking.truck = truck;
			await this.CART.STORE_BOOKING_DATA(booking);
			this.MAP.STORE_MAP_POSITION({
				center: {
					lat: this.mapRef?.$mapObject.getCenter().lat(),
					lng: this.mapRef?.$mapObject.getCenter().lng()
				},
				zoom: this.current_zoom
			});
			this.map.show_content = false;
			this.$router.push({ name: routesNames.upsells_1 });
		},
	}
});
