
import Vue from 'vue';
import MISC_DATA from '@/store/modules/MiscDataModule';

import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'KnownFrom',

	components: { SectionHeading },

	data() {
		return {
			awsPath: globalAws,
		}
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		mediaLogos(): any[] {
			return this.MISC_DATA.data_per_bu.media_logos;
		},

		shouldBeCarousel(): boolean {
			// true if mediaLogos is array of arrays
			const logos = this.MISC_DATA.data_per_bu.media_logos;
			if (!Array.isArray(logos) || logos.length === 0) { return false; }
			return Array.isArray(logos[0]) && logos[0].some((item) => typeof item === 'object' && item !== null);
		}
	}
});
